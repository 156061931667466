import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedUserData: null,
  checkCriteriaData: [],
  loader: false,
};

export const reducer = createSlice({
  name: "rootReducer",
  initialState,
  reducers: {
    setLoggedUserData: (state, { payload }) => {
      state.loggedUserData = payload;
    },
    setCheckCriteriaData: (state, { payload }) => {
      state.checkCriteriaData = payload;
    },
    setLoader: (state, { payload }) => {
      state.loader = payload;
    },
  },
});

export const { setLoggedUserData, setCheckCriteriaData, setLoader } = reducer.actions;

export default reducer.reducer;
