import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { requestOTP } from "../../utils/ApiCalls";
import VerificationCodeModal from "./VerificationCodeModal";

const EmailInputModal = ({ show, slug, handleClose, email, setEmail, onSuccess }) => {
  const [error, setError] = useState(null);
  const [showInputModal, setShowInputModal] = useState(false);

  const onSubmit = async () => {
    if (!email) {
      setError("Email address should not be blank");
    } else {
      try {
        const match = email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        if (!match) setError("Invalid email address.");
        else {
          await requestOTP(slug, email);
          setError(null);
          handleClose();
          setShowInputModal(true);
          toast.success(`Verification code sent! Please check your email.`);
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };

  return (
    <>
      <Modal
        className="blur-input-modal"
        centered
        show={show}
        onHide={() => {
          handleClose();
          setError(null);
          setEmail("");
        }}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Email Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`code-input-field ${!!error ? "error" : " "}`}>
            <input
              placeholder="Email address"
              className="form-control"
              onChange={(e) => {
                setEmail(e.target.value);
                setError(null);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSubmit();
                }
              }}
              value={email}
            />
          </div>
          {!!error && (
            <div className="form-error">
              <div className="error-text">
                <span className="info">i</span>
                <span>{error}</span>
              </div>
            </div>
          )}
          <div className="confirm-btn text-end mb-4 mt-3">
            <button onClick={onSubmit}>Confirm </button>
          </div>
        </Modal.Body>
      </Modal>

      <VerificationCodeModal
        show={showInputModal}
        slug={slug}
        email={email}
        handleClose={() => {
          setShowInputModal(false);
          setEmail("");
        }}
        length={6}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default EmailInputModal;
