import React, { useEffect, useState } from "react";
import { FaTwitter, FaGlobe, FaDiscord } from "react-icons/fa";
import { copyTextToClipboard, cutAddress } from "../../utils/ApiUtils";
import { ReactComponent as CopyIcon } from "../../assets/copy.svg";
import { removeHttp } from "../../utils/common";

function ProjectInfo({ projectData, showSnackBar }) {
  const [copyAllowlist, setCopyAllowlist] = useState(false);

  useEffect(() => {
    if (copyAllowlist) {
      setTimeout(() => {
        setCopyAllowlist(false);
      }, 2000);
    }
  }, [copyAllowlist]);

  return (
    <div className="top-sec-div-one">
      <div className="row align-items-center">
        {projectData?.projectIcon && (
          <div className={`${projectData.projectDescription ? "col-lg-4" : "col-md-3"} text-center`}>
            <figure className={`mb-0 ${projectData.projectDescription ? "medium-logo" : "small-logo"}`}>
              <img src={projectData?.projectIcon || ""} alt="leftpic" />
            </figure>
          </div>
        )}
        <div
          className={
            projectData.projectIcon
              ? `short-box ${projectData?.projectDescription ? "col-lg-8 short-box" : "col-lg-9 col-md-8"}`
              : "col-12"
          }
        >
          {projectData.projectName && (
            <div
              className={`${
                projectData.projectDescription ? "mb-1 mb-sm-3" : "mb-lg-3"
              } mt-2 d-flex justify-content-between`}
            >
              <div
                className="text-ellipsis project-title"
                style={{
                  color: "#fff",
                  wordBreak: "break-word",
                  maxWidth: "95%",
                }}
              >
                <p className="allow-name">{projectData.allowListName + " ✕ "} </p>
                <span className="partner-name blur-bg">{projectData.projectName} </span>
              </div>
              <div>
                <span className="custom-tooltip">
                  <CopyIcon
                    className="copy-icon-svg"
                    color="#fff"
                    onClick={() => {
                      showSnackBar("Collab Link copied successfully!");
                      if (!copyAllowlist) {
                        setCopyAllowlist(true);
                        copyTextToClipboard(`${window.location.origin}/${projectData?.urlSlug}`);
                      }
                    }}
                  />
                  <span className="tooltip-text custom-tooltip-bottom" style={{ top: "calc(100% - 8px)" }}>
                    {!copyAllowlist ? "Copy collab link" : "Collab Link copied successfully!"}
                  </span>
                </span>
              </div>
            </div>
          )}
          {projectData.projectDescription && <p className="project-description">{projectData.projectDescription}</p>}
          <div className="d-grid d-lg-flex social-media-div">
            {projectData?.twitter?.user?.username && (
              <a
                href={`https://twitter.com/${projectData?.twitter?.user?.username}`}
                target="_blank"
                rel="noreferrer"
                className="btn comon-btn-socal text-start text-ellipsis"
              >
                <FaTwitter /> @{projectData?.twitter?.user?.username}
              </a>
            )}
            <div className="custom-tooltip">
              <a
                href={`${projectData.projectWebsite}`}
                target="_blank"
                rel="noreferrer"
                className="btn comon-btn-socal text-start text-ellipsis"
              >
                <FaGlobe />{" "}
                {removeHttp(projectData.projectWebsite).length > 20
                  ? cutAddress(removeHttp(projectData.projectWebsite), 15)
                  : removeHttp(projectData.projectWebsite)}
              </a>
              <span className="tooltip-text custom-tooltip-bottom">{projectData.projectWebsite}</span>
            </div>
          </div>
          <div className="comon-btn-socal discord-link-data mt-lg-2 text-ellipsis">
            <div className="d-flex align-items-center gap-2">
              <div>
                <FaDiscord />
              </div>
              <span className="m-0 text-ellipsis" style={{ width: "80px" }}>
                {projectData?.discord?.guildData?.name}
              </span>
            </div>
            {projectData?.discordInviteLink && (
              <div className="discord-link text-ellipsis" style={{ width: "125px" }}>
                <a href={projectData?.discordInviteLink} target="_blank">
                  {removeHttp(projectData?.discordInviteLink)}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectInfo;
