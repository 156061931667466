import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineInfoCircle } from "react-icons/ai";
import moment from "moment-timezone";
import { toast } from "react-toastify";

import GroupInfo from "./GroupInfo";
import GroupCriteria from "./GroupCriteria";
import AccessCodeModal from "./AccessCodeModal";
import QuestionsRender from "./QuestionsRender";
import { ApiCall } from "../../utils/ApiUtils";
import { addToAllowlist, fetchFile } from "../../utils/ApiCalls";
import { setLoader } from "../../store/reducer";
import RegButton from "./RegButton";

const noOfLength = 6;

const GroupItem = ({
  groupItem,
  status,
  serverData,
  groupWiseRegs,
  setShowResults,
  projectData,
  slug,
  metamaskHandler,
  fillQuestionnaire,
  setFillQuestionnaire,
  fetchUserDetail,
  isRegTimer,
  index,
  showSnackBar,
}) => {
  const { id, groupName, groupType, minBalance, isPrivate, colsetext, questionnaire, isSchedule } = groupItem;
  const target = useRef(null);
  const dispatch = useDispatch();
  const token = useSelector(({ loggedUserData }) => loggedUserData?.token);
  const loggedUserData = useSelector(({ loggedUserData }) => loggedUserData);
  const checkCriteriaData = useSelector(({ checkCriteriaData }) => checkCriteriaData);
  const groupCriteriaData = checkCriteriaData?.find((item) => item.groupId == id) || {};

  const [show, setShow] = useState(false);
  const [groupIcon, setGroupIcon] = useState("");
  const [showError, setShowError] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [criteriaLoader, setCriteriaLoader] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isReadyQNA, setIsReadyQNA] = useState(false);
  const [isMaxLimitOver, setIsMaxLimitOver] = useState(false);
  const [verifiedAccessCode, setVerifiedAccessCode] = useState(null);
  const [accessCode, setAccessCode] = useState("");
  const [localLoader, setLocalLoader] = useState(false);

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  }, [showToast]);

  useEffect(() => {
    if (showTooltip) {
      setTimeout(() => {
        setShowTooltip(!showTooltip);
      }, 2000);
    }
  }, [showTooltip]);

  useEffect(() => {
    if (groupItem.groupType === "qna") {
      if (verifiedAccessCode || groupCriteriaData?.qualifiedForQNa) {
        setIsReadyQNA(true);
        fillQuestionnaire[index] = true;
        setFillQuestionnaire([...fillQuestionnaire]);
      }
    }
  }, [verifiedAccessCode, checkCriteriaData]);

  useEffect(() => {
    if (groupItem?.groupIcon) {
      fetchFile(groupItem?.groupIcon).then((icon) => setGroupIcon(icon));
    }
    RegistrantsMaxLimit();
  }, [groupItem]);

  const RegistrantsMaxLimit = async () => {
    if (groupItem) {
      await ApiCall("GET", `/rest/allowlist-registrants/getRegistrantsMaxLimitOverByGroup/${groupItem.id}`).then(
        (res) => {
          setIsMaxLimitOver(res?.isMaxLimitOver);
        }
      );
    }
  };

  const handleClose = () => {
    setAccessCode("");
    setShowError(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const register = async (groupId, qaInputData, isFromQa) => {
    try {
      setCriteriaLoader(true);
      if (!groupCriteriaData?.qualifiedForQNa) {
        await ApiCall("GET", `/rest/criteria-check/${slug}/${id}/socialsCheck`);
      }
      if (isFromQa) {
        const res = await ApiCall("POST", `/rest/registration/${slug}/criteriaCheck/${groupId}`);
        setCriteriaLoader(false);
        fetchUserDetail();
        if (res?.isQualified) {
          setIsReadyQNA(true);
        }
        return res?.isQualified;
      }

      await addToAllowlist(slug, groupId, verifiedAccessCode, qaInputData);
      setShowToast(true);
      !showToast && toast.success("You are registered!");
      setShowResults(true);
      setCriteriaLoader(false);
      fetchUserDetail();
    } catch (error) {
      toast.error(error.message || "Something went wrong.");
      dispatch(setLoader(false));
      setCriteriaLoader(false);
      fetchUserDetail();
    }
  };

  const isSocialConnected = loggedUserData?.discord && loggedUserData?.twitter && loggedUserData?.email;
  const isStartedTime = isSchedule && moment(groupItem?.scheduleStartDate).diff(moment(), "seconds") <= 0;
  const isScheduleInTime = isStartedTime && moment(groupItem?.scheduleEndDate).diff(moment(), "seconds") >= 0;
  const isReadyToReg = isSchedule ? isScheduleInTime && isSocialConnected : isSocialConnected;
  const isRegClicked = Object.keys(groupCriteriaData).length > 0;
  const isQNAReadyToReg = fillQuestionnaire[index] && questionnaire?.questions;

  return (
    <div className="comon-ge1-div" key={id}>
      <div className="d-lg-flex align-items-baseline justify-content-between gap-2">
        <div className="group-detail">
          {groupIcon && (
            <div className="group-detail-icon">
              <img src={groupIcon} alt="groupIcon" />
            </div>
          )}
          <h2
            className="my-md-0 mb-0 mt-0 text-ellipsis"
            style={{
              color: "#ffffff",
            }}
          >
            {groupName}
          </h2>
          <div className="group-type blur-bg">
            <span>
              {groupType === "first-come" && "FCFS"}
              {groupType === "raffle" && "RAFFLE"}
              {groupType === "qna" && "QnA"}
              {groupType === "public" && "PUBLIC"}
            </span>
          </div>
        </div>
        {isSchedule && (
          <div className="mb-2 my-lg-0">
            {isStartedTime ? (
              <h5>
                {isScheduleInTime ? "Ends on " : "Ended on "}
                {moment(groupItem?.scheduleEndDate).tz(moment.tz.guess()).format("Do MMM yyyy, h:mm a")}
              </h5>
            ) : (
              <h5>
                {moment(groupItem?.scheduleStartDate).tz(moment.tz.guess()).format("[Starts on] Do MMM yyyy, h:mm a")}
              </h5>
            )}
          </div>
        )}
      </div>
      {groupItem?.description && <p className="group-detail-des">{groupItem?.description}</p>}
      <div
        className={`col-lg-12 sp-div-margin mt-lg-4 mt-md-3 mt-sm-2 ${
          groupItem?.referralCodesRequired ? "mb-lg-3" : ""
        }`}
        style={{ marginBottom: groupItem?.referralCodesRequired ? 10 : 0 }}
      >
        {groupItem?.referralCodesRequired && (
          <div className="blur-bg access-code-info">
            <AiOutlineInfoCircle />
            <p>Access Code is mandatory to register in this collab.</p>
          </div>
        )}
      </div>
      <div
        className={`col-lg-12 sp-div-margin mb-3 ${
          (isPrivate || (Boolean(minBalance) && minBalance > 0)) === true ? "mb-sm-4" : "mb-sm-1"
        }`}
      >
        <GroupInfo groupItem={groupItem} groupWiseRegs={groupWiseRegs} />
        {(isPrivate || (Boolean(minBalance) && minBalance > 0)) === true && (
          <GroupCriteria
            groupItem={groupItem}
            status={status}
            serverData={serverData}
            groupCriteriaData={groupCriteriaData}
            criteriaLoader={criteriaLoader}
          />
        )}
      </div>
      <div id="close" className="text-center close-text">
        {colsetext}
      </div>
      {status === "connected" && token && !isMaxLimitOver && (
        <div className="questionnaire-wrap">
          <div className="question-btn-wrap">
            <div className="custom-tooltip">
              <div
                style={{ display: "inline-block" }}
                className="question-btn-div"
                onClick={() =>
                  !isReadyToReg &&
                  showSnackBar(
                    isSocialConnected
                      ? !isStartedTime
                        ? "Registration has not started yet."
                        : "Registration is closed"
                      : "Please connect all social accounts."
                  )
                }
              >
                <button
                  className="btn"
                  disabled={!isReadyToReg || verifiedAccessCode || isRegTimer}
                  onClick={() => handleShow()}
                >
                  {verifiedAccessCode ? "Access code verified" : "Use Access Code"}
                </button>
                {/* <button className="btn" disabled={!isReadyToReg || verifiedAccessCode} onClick={() => handleShow()}>
                  {verifiedAccessCode ? "Access code verified" : "Use Access Code"}
                </button> */}
              </div>
              {!isRegTimer ? (
                !isReadyToReg && (
                  <span className="tooltip-text custom-tooltip-bottom">
                    {isSocialConnected
                      ? !isStartedTime
                        ? "Registration has not started yet."
                        : "Registration is closed"
                      : "Please connect all social accounts."}
                  </span>
                )
              ) : (
                <span className="tooltip-text custom-tooltip-bottom">
                  Too many requests received. Please try again when timer stops.
                </span>
              )}
            </div>

            {groupItem.numberOfCodes > 0 ? (
              !groupItem?.referralCodesRequired && <span className="or-text">OR</span>
            ) : (
              <span className="or-text">OR</span>
            )}
            <AccessCodeModal
              show={show}
              slug={slug}
              groupId={id}
              groupItem={groupItem}
              showError={showError}
              setShowError={setShowError}
              handleClose={handleClose}
              accessCode={accessCode}
              setAccessCode={setAccessCode}
              setVerifiedAccessCode={setVerifiedAccessCode}
            />
            <RegButton
              isRegTimer={isQNAReadyToReg ? false : isRegTimer}
              isSocialConnected={isSocialConnected}
              verifiedAccessCode={verifiedAccessCode}
              isReadyToReg={isReadyToReg}
              isRegClicked={isRegClicked}
              isStartedTime={isStartedTime}
              className={`${fillQuestionnaire[index] ? "active" : ""}`}
              isReadyQNA={verifiedAccessCode ? true : isReadyQNA}
              groupItem={groupItem}
              isCriteriaQualified={groupCriteriaData?.qualifiedForQNa || groupCriteriaData?.isQualified}
              onClick={async () => {
                if (isSocialConnected) {
                  if (groupType === "qna") {
                    let result = verifiedAccessCode || groupCriteriaData?.qualifiedForQNa ? true : false;
                    if (!result) {
                      result = await register(id, null, true);
                    }
                    if (result) {
                      fillQuestionnaire[index] = !fillQuestionnaire[index];
                      setFillQuestionnaire([...fillQuestionnaire]);
                    }
                  } else {
                    register(id);
                  }
                }
              }}
              criteriaLoader={criteriaLoader}
            />
          </div>
          {isQNAReadyToReg && (
            <QuestionsRender
              isRegTimer={isRegTimer}
              questions={questionnaire?.questions}
              groupId={id}
              register={register}
              groupItem={groupItem}
              verifiedAccessCode={verifiedAccessCode}
              isReadyToReg={isReadyToReg}
              showSnackBar={showSnackBar}
              criteriaLoader={criteriaLoader}
              isCriteriaQualified={groupCriteriaData?.qualifiedForQNa || groupCriteriaData?.isQualified}
            />
          )}
        </div>
      )}
      <div className={`d-flex align-items-center justify-content-end mt-2`}>
        {isMaxLimitOver ? (
          <p className="text-center re-text m-0">Registration Closed</p>
        ) : (
          (status !== "connected" || !token) && (
            <button type="button" className="btn comon-btn-ree funt-btn reg-btn mb-sm-4 mb-3" onClick={metamaskHandler}>
              {status === "connected" ? "Authenticate" : "Connect Wallet"}
              <span className="btn-arowwn">
                <img src="images/arrow.png" alt="bn" className="d-lg-block" />
              </span>
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default GroupItem;
