import moment from "moment";

const RegistrationBlock = ({ userRegGroupData, userRegData, account }) => {
  const regDate = moment(userRegData?.createdAt).format("Do of MMMM, YYYY");
  const isWaiting = userRegGroupData?.isPublish ? userRegGroupData?.winners.length === 0 : true;
  const isWinner = userRegGroupData.winners.map((item) => item.toLowerCase()).includes(account.toLowerCase());
  const { isPrivate } = userRegGroupData;

  return (
    <div className="col-lg-12 sp-div-margin mb-3 blur-bg py-md-4 py-3">
      <p className="text-center re-text m-0">
        {(() => {
          if (!isPrivate) return `Registered on ${regDate}.`;

          switch (userRegGroupData?.groupType) {
            case "qna":
              if (isWinner) {
                return `Congratulations, you are selected!`;
              } else {
                return `Registered on ${regDate}. Awaiting result.`;
              }

            case "raffle":
              if (isWaiting) {
                return `Registered on ${regDate}. Awaiting the result.`;
              } else if (isWinner) {
                return `Registered on ${regDate}. You won!`;
              } else {
                return `Registered on ${regDate}. You lost.`;
              }

            case "first-come":
              return `Registered on ${regDate}.`;
          }
        })()}
      </p>
    </div>
  );
};

export default RegistrationBlock;
