import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { requestOTP, verifyOTP } from "../../utils/ApiCalls";
import InputCode from "./InputCode";

const VerificationCodeModal = ({ show, handleClose, slug, email, length, onSuccess }) => {
  const initialOTP = "";

  const [otp, setOtp] = useState("");
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      await verifyOTP(slug, email, otp);
      onSuccess();
      setShowError(false);
      setLoading(false);
      resetOTP();
      toast.success(`Code verified!`);
    } catch (error) {
      setShowError(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const resendOTP = async () => {
    await requestOTP(slug, email);
    setShowError(null);
    setOtp(initialOTP);
  };

  const resetOTP = () => {
    setOtp(initialOTP);
    setShowError(null);
    handleClose();
  };

  return (
    <Modal className="blur-input-modal" centered show={show} onHide={resetOTP}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title>Email Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{`Enter the code sent to ${email}`}</p>
        <div className="code-input-field">
          <InputCode
            length={length}
            loading={loading}
            accessCode={otp}
            setAccessCode={setOtp}
            showError={showError}
            setShowError={setShowError}
            inputMode={"numeric"}
          />
        </div>
        {!!showError && (
          <div className="form-error">
            <div className="error-text">
              <span className="info">i</span>
              <span>
                {showError || `Invalid verification code.`}
                <span className="resend-btn" style={{ cursor: "pointer" }} onClick={resendOTP}>
                  Resend
                </span>
              </span>
            </div>
          </div>
        )}
        <div className="confirm-btn text-end mb-4 mt-3">
          <button type="button" onClick={onSubmit}>
            Confirm
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VerificationCodeModal;
