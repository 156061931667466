import React, { useState } from "react";
import { useSelector } from "react-redux";
import { GrMail } from "react-icons/gr";
import { FaTwitter, FaDiscord } from "react-icons/fa";
import { useAccount } from "wagmi";

import { API_ALLOWLIST_URL } from "../../utils/ApiUtils";
import EmailInputModal from "./EmailInputModal";
import TwitterConnectModal from "./TwitterConnectModal";

function Socials({ token, slug, isRegistered, paramGroupId, refresh, showSnackBar }) {
  const navRefnext = React.useRef(null);
  const navRefnextT = React.useRef(null);
  const { status } = useAccount();

  const loggedUserData = useSelector(({ loggedUserData }) => loggedUserData);

  const [email, setEmail] = useState(null);
  const [show, setShow] = useState(false);
  const [twitterConnectModal, setTwitterConnectModal] = useState(false);

  const discordOnClick = async (e) => {
    e.preventDefault();
    window.open(
      API_ALLOWLIST_URL +
        `/rest/registration/${slug}/discord/request?time=${new Date().getTime()}&accessToken=Bearer ${token}&groupId=${paramGroupId}`,
      "_self"
    );
  };

  const twitterOnClick = async (e) => {
    e.preventDefault();
    window.open(
      API_ALLOWLIST_URL +
        `/rest/registration/${slug}/twitter/request?time=${new Date().getTime()}&accessToken=Bearer ${token}&groupId=${paramGroupId}`,
      "_self"
    );
  };

  const emailOnClick = async (e) => {
    e.preventDefault();
    setShow(true);
  };

  const onSuccess = () => {
    refresh();
    setEmail("");
  };

  return (
    <>
      {!isRegistered && (
        <div className="connect-socials-text">
          <h2>Connect Socials</h2>
          <p>
            For security, you are allowed to connect your social accounts (Discord, Twitter and Email) only once. Please
            ensure that you are linking the right accounts before proceeding.
          </p>
        </div>
      )}

      <div className="row row-cols-1 row-cols-lg-3 gy-lg-0">
        <div className="col mb-lg-3">
          <div className="comon-pre-bn" ref={navRefnext}>
            {loggedUserData?.discord ? (
              <div className="text-center comon-sc-v">
                <span className="icon-m1">
                  <FaDiscord />
                </span>
                <p className="text-ellipsis">
                  <b> {loggedUserData?.discord?.userData.username}</b>
                </p>
              </div>
            ) : (
              <div className="discord-div comon-sc-f">
                <div
                  className="d-flex justify-content-between w-100"
                  onClick={() =>
                    !token && showSnackBar(`${status === "connected" ? "Authenticate" : "Connect"} your wallet`)
                  }
                >
                  <span className="sc-mn-icon">
                    <FaDiscord style={{ opacity: !token ? 0.5 : 1 }} />
                  </span>
                  <div className="custom-tooltip w-100">
                    <button
                      type="button"
                      disabled={!token}
                      style={{ border: "none" }}
                      className="btn w-100 p-0 d-flex align-items-center justify-content-center gap-2"
                      onClick={discordOnClick}
                    >
                      <span className="btn comon-btn-ree active-id-btn"> Connect</span>
                    </button>
                    {!token && (
                      <span className="tooltip-text custom-tooltip-bottom">
                        {status === "connected" ? "Authenticate" : "Connect"} your wallet
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col mb-lg-3">
          <div className="comon-pre-bn" ref={navRefnextT}>
            {loggedUserData?.twitter ? (
              <div className="text-center comon-sc-v">
                <span className="icon-m1">
                  <FaTwitter />
                </span>
                <p className="text-ellipsis custom-tooltip">
                  <b> {loggedUserData?.twitter?.user.username}</b>
                  <span className="tooltip-text custom-tooltip-bottom">{loggedUserData?.twitter?.user.username}</span>
                </p>
              </div>
            ) : (
              <div className="twitw-div1 comon-sc-f">
                <div
                  className="d-flex justify-content-between w-100"
                  onClick={() =>
                    !token && showSnackBar(`${status === "connected" ? "Authenticate" : "Connect"} your wallet`)
                  }
                >
                  <span className="sc-mn-icon">
                    <FaTwitter style={{ opacity: !token ? 0.5 : 1 }} />
                  </span>
                  <div className="custom-tooltip w-100">
                    <button
                      type="button"
                      disabled={!token}
                      style={{ border: "none" }}
                      className="btn w-100 d-flex p-0 align-items-center justify-content-center gap-2 desktop-view"
                      onClick={twitterOnClick}
                    >
                      <span className="btn comon-btn-ree active-id-btn">Connect</span>
                    </button>
                    <button
                      type="button"
                      disabled={!token}
                      style={{ border: "none" }}
                      className="btn w-100 d-flex p-0 align-items-center justify-content-center gap-2 mobile-view"
                      onClick={() => setTwitterConnectModal(true)}
                    >
                      <span className="btn comon-btn-ree active-id-btn">Connect</span>
                    </button>
                    {!token && (
                      <span className="tooltip-text custom-tooltip-bottom">
                        {status === "connected" ? "Authenticate" : "Connect"} your wallet
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col mb-lg-3">
          <div className="comon-pre-bn" ref={navRefnextT}>
            {loggedUserData?.email ? (
              <div className="text-center comon-sc-v">
                <span className="icon-m1">
                  <GrMail />
                </span>
                <p className="text-ellipsis">
                  <b> {loggedUserData?.email}</b>
                </p>
              </div>
            ) : (
              <div className="discord-div comon-sc-f">
                <div
                  className="d-flex justify-content-between w-100"
                  onClick={() =>
                    !token && showSnackBar(`${status === "connected" ? "Authenticate" : "Connect"} your wallet`)
                  }
                >
                  <span className="sc-mn-icon">
                    <GrMail style={{ opacity: !token ? 0.5 : 1 }} />
                  </span>
                  <div className="custom-tooltip w-100">
                    <button
                      type="button"
                      disabled={!token}
                      style={{ border: "none" }}
                      className="btn w-100 d-flex p-0 align-items-center justify-content-center gap-2"
                      onClick={emailOnClick}
                    >
                      <span className="btn comon-btn-ree active-id-btn"> Connect & Verify </span>
                    </button>
                    {!token && (
                      <span className="tooltip-text custom-tooltip-bottom">
                        {status === "connected" ? "Authenticate" : "Connect"} your wallet
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <EmailInputModal
        show={show}
        slug={slug}
        email={email}
        setEmail={setEmail}
        handleClose={() => setShow(false)}
        onSuccess={onSuccess}
      />
      {twitterConnectModal && (
        <TwitterConnectModal
          show={twitterConnectModal}
          onConnect={twitterOnClick}
          handleClose={() => setTwitterConnectModal(false)}
        />
      )}
    </>
  );
}

export default Socials;
