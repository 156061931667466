import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { map } from "lodash";
import { ReactComponent as CopyIcon } from "../../assets/copy.svg";
import { copyTextToClipboard } from "../../utils/ApiUtils";

const ReferralCodeBlock = ({ referralCodes, showSnackBar }) => {
  const allCodes = referralCodes[0].codes;
  const usedCodes = allCodes.filter((item) => item.isClaimed);
  const unusedCodes = allCodes.filter((item) => !item.isClaimed);

  const [stopCopy, setStopCopy] = useState(false);
  const [stopCopyAll, setStopCopyAll] = useState(false);
  const [selectedCodes, setSelectedCodes] = useState(allCodes);

  useEffect(() => {
    if (stopCopy) {
      setTimeout(() => {
        setStopCopy(false);
      }, 2000);
    }
  }, [stopCopy]);

  useEffect(() => {
    if (stopCopyAll) {
      setTimeout(() => {
        setStopCopyAll(false);
      }, 2000);
    }
  }, [stopCopyAll]);

  const handleSelect = (e) => {
    if (e === "all") setSelectedCodes(allCodes);
    else if (e === "Used") setSelectedCodes(usedCodes);
    else if (e === "Unused") setSelectedCodes(unusedCodes);
  };

  return (
    <div className="referral-codes-box">
      <h2 className="my-0 text-white">Referral Codes</h2>
      <p className="sub-title">Contribute to our growth by referring people you know by using these codes</p>
      <div className="code-boxes">
        <Tabs defaultActiveKey="all" id="uncontrolled-tab-example" onSelect={(e) => handleSelect(e)}>
          <Tab eventKey="all" title={`All(${allCodes.length})`}>
            <ul className={`code ${allCodes.length === 3 ? "justify-content-center" : ""}`}>
              {allCodes?.map((item, index) => (
                <li key={index}>
                  <span>{item.code}</span>
                  <button className="btn p-0 border-0">
                    <div className={stopCopyAll && "custom-tooltip"}>
                      <CopyIcon
                        width={16}
                        color="#fff"
                        onClick={() => {
                          showSnackBar("Code copied!");
                          setStopCopyAll(true);
                          copyTextToClipboard(item?.code);
                        }}
                      />
                      <span className={stopCopyAll && "tooltip-text custom-tooltip-bottom"}>
                        {stopCopyAll && "Code copied!"}
                      </span>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </Tab>
          <Tab eventKey={`Used`} title={`Used(${usedCodes.length})`}>
            <ul className={`code ${usedCodes.length === 2 ? "justify-content-center" : ""}`}>
              {usedCodes.length > 0 ? (
                usedCodes?.map(
                  (item, index) =>
                    item.isClaimed && (
                      <li key={index}>
                        <span>{item.code}</span>
                        <button className="btn p-0 border-0">
                          <div className={stopCopyAll && "custom-tooltip"}>
                            <CopyIcon
                              width={16}
                              color="#fff"
                              onClick={() => {
                                showSnackBar("Code copied!");
                                setStopCopyAll(true);
                                copyTextToClipboard(item?.code);
                              }}
                            />
                            <span className={stopCopyAll && "tooltip-text custom-tooltip-bottom"}>
                              {stopCopyAll && "Code copied!"}
                            </span>
                          </div>
                        </button>
                      </li>
                    )
                )
              ) : (
                <p className="w-100 text-center">You haven't used any referral code yet</p>
              )}
            </ul>
          </Tab>
          <Tab eventKey={`Unused`} title={`Unused(${unusedCodes.length})`}>
            <ul className={`code ${unusedCodes.length === 2 ? "justify-content-center" : ""}`}>
              {unusedCodes.length > 0 ? (
                unusedCodes?.map(
                  (item, index) =>
                    !item.isClaimed && (
                      <li key={index}>
                        <span>{item.code}</span>
                        <button className="btn p-0 border-0">
                          <div className={stopCopyAll && "custom-tooltip"}>
                            <CopyIcon
                              width={16}
                              color="#fff"
                              onClick={() => {
                                showSnackBar("Code copied!");
                                setStopCopyAll(true);
                                copyTextToClipboard(item?.code);
                              }}
                            />
                            <span className={stopCopyAll && "tooltip-text custom-tooltip-bottom"}>
                              {stopCopyAll && "Code copied!"}
                            </span>
                          </div>
                        </button>
                      </li>
                    )
                )
              ) : (
                <p className="w-100 text-center">You have used all the referral codes</p>
              )}
            </ul>
          </Tab>
        </Tabs>
        {selectedCodes.length > 0 && (
          <div className="d-flex justify-content-end copy-all">
            <div className={stopCopy && "custom-tooltip"}>
              <button
                onClick={() => {
                  showSnackBar("Code copied!");
                  setStopCopy(true);
                  copyTextToClipboard(map(selectedCodes, "code").join(", "));
                }}
              >
                COPY ALL <CopyIcon color="#fffF" className="ms-1" />
              </button>
              <span className={stopCopy && "tooltip-text custom-tooltip-bottom"}>{stopCopy && "Code copied!"}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferralCodeBlock;
