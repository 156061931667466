import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useAccount, useSignMessage, useNetwork, useSwitchNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";

import Footer from "../Footer";
import Header from "../Header";
import Oops from "../assets/oops.svg";
import Socials from "./components/Socials";
import GroupInfo from "./components/GroupInfo";
import GroupItem from "./components/GroupItem";
import ProjectInfo from "./components/ProjectInfo";
import ReferralCodeBlock from "./components/ReferralCodeBlock";
import RegistrationBlock from "./components/RegistrationBlock";
import { ApiAuth, ApiCall, setAuthHeader } from "../utils/ApiUtils";
import { setLoggedUserData, setLoader, setCheckCriteriaData } from "../store/reducer";
import { getProjectDetails, fetchFile, getUserDetails } from "../utils/ApiCalls";

const d = new Date();
const time = d.getTime();

function Register() {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const toastId = useRef(null);
  const { address, status } = useAccount();
  const { open } = useWeb3Modal();
  const { isLoading } = useSwitchNetwork();

  const [searchParams, setSearchParams] = useSearchParams();
  const paramGroupId = searchParams.get("groupId");
  const errorDiscord = searchParams.get("error");

  const [serverData, setServerData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [refetchUserDetails, setRefetchUserDetails] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [userRegData, setUserRegData] = useState({});
  const [projectData, setProjectData] = useState(null);
  const [isWinnerRole, setIsWinnerRole] = useState([]);
  const [isWinnerGuildData, setIsWinnerGuildData] = useState([]);
  const [groupWiseRegs, setGroupWiseRegs] = useState([]);
  const [isRegTimer, setIsRegTimer] = useState(null);
  const [referralCodes, setReferralCodes] = useState([]);
  const [isSlugNotFound, setIsSlugNotFound] = useState(false);
  const [fillQuestionnaire, setFillQuestionnaire] = useState([]);
  const [groupIcon, setGroupIcon] = useState("");

  const token = useSelector(({ loggedUserData }) => loggedUserData?.token);
  const loggedUserData = useSelector(({ loggedUserData }) => loggedUserData);

  document.title = projectData?.projectName
    ? projectData?.projectName + " - Collab Registration"
    : "Collab Registration";

  useEffect(() => {
    if (status === "notConnected") {
      dispatch(setLoggedUserData(null));
    }
  }, [status]);

  useEffect(() => {
    if (!slug) {
      window.location.href = process.env.REACT_APP_REDIRECT_URL;
    }
  }, [slug]);

  useEffect(() => {
    if (projectData) {
      projectData.allowlistGroup
        ?.filter((item) => item?.isActive)
        ?.map((item, index) => {
          index <= fillQuestionnaire.length && setFillQuestionnaire([...fillQuestionnaire, item.length <= index]);
        });
    }
  }, [projectData]);

  useEffect(() => {
    if (errorDiscord) {
      if (errorDiscord === "DISCORDNOTAVAILABLE") {
        setTimeout(() => {
          toast.error(
            "This discord account is already connected to a different wallet. Use another account to register."
          );
        }, 500);
        searchParams.delete("error");
        setSearchParams(searchParams);
      }
      if (errorDiscord === "TWITTERNOTAVAILABLE") {
        setTimeout(() => {
          toast.error(
            "This twitter account is already connected to a different wallet. Use another account to register."
          );
        }, 500);
        searchParams.delete("error");
        setSearchParams(searchParams);
      }
    }
  }, [errorDiscord]);

  useEffect(() => {
    if (
      address &&
      token &&
      loggedUserData &&
      loggedUserData.wallet_address &&
      loggedUserData.wallet_address.toLowerCase() !== address.toLowerCase()
    ) {
      localStorage.clear();
      window.location.reload();
    }
  }, [address]);

  const { signMessage } = useSignMessage({
    async onSuccess(data, variables) {
      const payload = {
        message: variables.message,
        signature: data,
        wallet_address: address,
      };

      const resp = await ApiAuth(payload);
      if (resp) {
        dispatch(setLoggedUserData(resp));
        setAuthHeader(resp.token);
        getUserDetails(slug)
          .then((res) => {
            dispatch(
              setLoggedUserData({
                ...resp,
                ...res?.userDetail,
              })
            );
          })
          .catch((error) => {
            if (error?.response?.data?.status === 401) {
              dispatch(setLoggedUserData(null));
            }
          });
        return resp;
      }
    },
  });

  const metamaskHandler = async () => {
    if (status === "connected") {
      const message = `{
        address: ${address},
        timestamp: ${parseInt(Date.now() / 1000)}
      }`;
      signMessage({ message });
    } else {
      open();
    }
  };

  useEffect(() => {
    if (loggedUserData) {
      setAuthHeader(loggedUserData.token);
    }
  }, [loggedUserData]);

  useEffect(() => {
    dispatch(setLoader(true));

    getProjectDetails(slug)
      .then(async (resdata) => {
        const projectBackgroundImage = await fetchFile(resdata.projectBackgroundImage);
        const projectLogo = await fetchFile(resdata.projectLogo);
        const projectIcon = await fetchFile(resdata.projectIcon);
        resdata.projectBackgroundImage = projectBackgroundImage;
        resdata.projectLogo = projectLogo;
        resdata.projectIcon = projectIcon;
        setProjectData(resdata);
        setIsSlugNotFound(false);
        dispatch(setLoader(false));

        ApiCall("GET", `/rest/allowlist-registrants/count/getRegistrantsCount/${resdata.id}`)
          .then((result) => {
            setGroupWiseRegs(result);
            dispatch(setLoader(false));
          })
          .catch((error) => {
            console.log(error);
            dispatch(setLoader(false));
          });
      })
      .catch((e) => {
        console.log(e);
        e?.response?.status === 500 && setIsSlugNotFound(true);
        dispatch(setLoader(false));
      });
  }, [showResults]);

  useEffect(() => {
    if (status === "connected" && token && projectData) {
      getUserDetails(slug)
        .then((res) => {
          if (res?.userDetail) {
            const { criteriaCheckGroupInfo, criteriaCheckInfo, ...rest } = res?.userDetail || {};
            const isTwitterExpire = time > res?.userDetail?.twitter?.token?.expires_at;
            if (criteriaCheckGroupInfo) {
              dispatch(setCheckCriteriaData(criteriaCheckGroupInfo));
            }
            if (criteriaCheckInfo) {
              checkIsTwiceClickReg(criteriaCheckInfo);
            }

            if (isTwitterExpire) {
              delete rest["twitter"];
              // toast.error("Twitter session timed out, reconnect Twitter to continue");
            }
            const { discord: _0, twitter: _1, email: _2, ...oldLoggedUserData } = loggedUserData;
            dispatch(
              setLoggedUserData({
                ...oldLoggedUserData,
                ...rest,
              })
            );
          }
          if (res?.allowListRegistration) {
            setIsRegistered(true);
            setUserRegData(res?.allowListRegistration);
          }
          if (res?.accessCodes?.length > 0) {
            setReferralCodes(res?.accessCodes);
          }
        })
        .catch((error) => {
          if (error?.response?.data?.status === 401) {
            dispatch(setLoggedUserData(null));
          }
        });
    }
  }, [status, token, refetchUserDetails, projectData]);

  const checkIsTwiceClickReg = (criteriaCheckInfo) => {
    let isExceeded = false;
    let isRecentDates = Object.values(criteriaCheckInfo || {}).sort(
      (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()
    );
    if (isRecentDates.length > 0) {
      isRecentDates.map((item, index) => {
        if (
          index !== 0 &&
          moment(isRecentDates[0].time).format("Do MMM yyyy, h:mm a") ===
            moment(item.time).format("Do MMM yyyy, h:mm a") &&
          item.count >= 4
        ) {
          isRecentDates[0] = item;
        }
      });
      if (isRecentDates[0].count >= 4) {
        isExceeded = isRecentDates[0];
      }
    }

    if (isExceeded) {
      const diffMin = moment().diff(moment(isExceeded.time), "minutes");

      if (diffMin < 5) {
        const diffSec = moment(isExceeded.time).add(5, "minutes").diff(moment(), "seconds");
        setIsRegTimer(Math.abs(diffSec));
        const timer = setInterval(() => {
          setIsRegTimer((ddd) => {
            if (ddd <= 0) {
              clearInterval(timer);
              return null;
            } else {
              return ddd - 1;
            }
          });
        }, 1000);
      }
    }
  };

  const userRegGroupData = projectData
    ? projectData?.allowlistGroup.find((group) => group.id === userRegData?.groupId)
    : null;

  const activeGroups = projectData?.allowlistGroup
    ?.filter((item) => (item?.isSchedule ? true : item?.isActive))
    ?.filter((item) => (paramGroupId ? paramGroupId === item.id : true));

  const isWaiting = userRegGroupData?.isPublish ? userRegGroupData?.winners.length === 0 : true;
  const isWinner =
    userRegGroupData && userRegGroupData?.winners.map((item) => item.toLowerCase()).includes(address.toLowerCase());

  useEffect(() => {
    if (
      userRegGroupData?.assignDiscordRoleOnSelectionFlag &&
      userRegGroupData?.assignDiscordRoleOnSelection?.guildId !== ""
    ) {
      const winnerGuildData =
        projectData?.discord?.guildsData?.length > 0
          ? projectData?.discord?.guildsData?.filter(
              (item) => item.id === userRegGroupData?.assignDiscordRoleOnSelection?.guildId
            )
          : projectData?.discord?.guildsData?.roles?.filter(
              (item) => item.id === userRegGroupData?.assignDiscordRoleOnSelection?.guildId
            );
      setIsWinnerGuildData(winnerGuildData);
      ApiCall("GET", `/http/discord/getRoles/${userRegGroupData?.assignDiscordRoleOnSelection?.guildId}`).then(
        async (result) => {
          const mappedData = result.map((item) => ({ roleName: item.name, roleId: item.id }));
          const winnerRole = mappedData?.filter(
            (item) => item.roleId === userRegGroupData?.assignDiscordRoleOnSelection?.roleId
          );
          setIsWinnerRole(winnerRole);
        }
      );
    }
  }, [isWinner, userRegGroupData]);

  useEffect(() => {
    getAddress();
  }, []);

  useEffect(() => {
    if (userRegGroupData?.groupIcon && !groupIcon) {
      fetchFile(userRegGroupData?.groupIcon).then((icon) => setGroupIcon(icon));
    }
  }, [userRegGroupData]);

  useEffect(() => {
    if (isLoading) {
      dispatch(setLoader(true));
    } else {
      toast.dismiss(toastId.current);
      dispatch(setLoader(false));
    }
  }, [isLoading]);

  const getAddress = async () => {
    const response = await ApiCall("GET", `/http/discord/getBotGuilds`);
    setServerData(response);
  };

  const showSnackBar = (message) => {
    toast.dismiss();
    return toast(message, {
      position: "bottom-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "mobile-snackbar",
    });
  };

  return (
    <div>
      {projectData && (
        <>
          <div>
            {projectData?.isActive ? (
              <div id="main-total" className={loggedUserData ? "new-show-socal-div" : null}>
                <Header projectData={projectData} metamaskHandler={metamaskHandler} />
                <section className="home-page comon-page float-start w-100 sp-margin">
                  <div className="container">
                    <ProjectInfo projectData={projectData} showSnackBar={showSnackBar} />
                    {isRegistered && userRegGroupData ? (
                      userRegData?.registrantType !== "ByOwner" && (
                        <div className="comon-ge1-div">
                          <Socials
                            token={token}
                            slug={slug}
                            isRegistered={isRegistered}
                            paramGroupId={paramGroupId}
                            refresh={() => setRefetchUserDetails(!refetchUserDetails)}
                            showSnackBar={showSnackBar}
                          />
                        </div>
                      )
                    ) : (
                      <>
                        <div className="comon-ge1-div">
                          <Socials
                            token={token}
                            slug={slug}
                            isRegistered={isRegistered}
                            paramGroupId={paramGroupId}
                            refresh={() => setRefetchUserDetails(!refetchUserDetails)}
                            showSnackBar={showSnackBar}
                          />
                        </div>
                        {activeGroups.length <= 0 &&
                          (token && isRegistered ? (
                            <div className="comon-ge1-div already-reg">
                              <h1 style={{ maxWidth: "100%" }}>
                                {`${paramGroupId ? "This collab is" : "All collabs are"}`} currently paused. Please come
                                back later
                              </h1>
                            </div>
                          ) : loggedUserData?.email && loggedUserData?.twitter && loggedUserData?.discord ? (
                            <div className="comon-ge1-div already-reg">
                              <h1 style={{ maxWidth: "100%" }}>Collabs are inactive at the moment.</h1>
                              <h1 style={{ maxWidth: "100%" }}>You will be notified when relevant collabs go live.</h1>
                            </div>
                          ) : (
                            <div className="comon-ge1-div already-reg">
                              <h1 style={{ maxWidth: "100%" }}>
                                Connect socials above and we will email you when relevant collabs go live.
                              </h1>
                            </div>
                          ))}
                      </>
                    )}
                    <div id="gen-div" className="gen-list-div">
                      {isRegistered && userRegGroupData ? (
                        paramGroupId && userRegGroupData.id !== paramGroupId ? (
                          <div className="comon-ge1-div already-reg">
                            <h1>
                              You're already registered in{" "}
                              <b
                                style={{
                                  color: "#ffffff",
                                }}
                              >
                                {userRegGroupData?.groupName}
                              </b>{" "}
                              collab of this project.
                            </h1>
                          </div>
                        ) : (
                          <div className="comon-ge1-div">
                            <div>
                              <div className="group-detail">
                                {groupIcon && (
                                  <div className="group-detail-icon">
                                    <img src={groupIcon} alt="groupIcon" />
                                  </div>
                                )}

                                <h2
                                  className="my-md-0 mb-2 mt-0"
                                  style={{
                                    color: "#ffffff",
                                  }}
                                >
                                  {userRegGroupData?.groupName}
                                </h2>
                              </div>
                              {userRegGroupData?.description && (
                                <p className="group-detail-des">{userRegGroupData?.description}</p>
                              )}
                              <div className="col-lg-12 sp-div-margin mt-lg-3">
                                <GroupInfo groupItem={userRegGroupData} groupWiseRegs={groupWiseRegs} />
                              </div>

                              {referralCodes &&
                                referralCodes[0] &&
                                referralCodes[0].codes &&
                                referralCodes[0].codes.length > 0 &&
                                referralCodes[0]?._id === userRegData?.groupId && (
                                  <div className="col-lg-12 sp-div-margin mb-3 blur-bg py-md-4 py-3">
                                    <ReferralCodeBlock referralCodes={referralCodes} showSnackBar={showSnackBar} />
                                  </div>
                                )}
                              {userRegGroupData && (
                                <RegistrationBlock
                                  userRegGroupData={userRegGroupData}
                                  userRegData={userRegData}
                                  account={address}
                                />
                              )}
                              {!isWaiting &&
                                isWinner &&
                                isWinnerRole.length > 0 &&
                                isWinnerGuildData.length > 0 &&
                                userRegGroupData?.groupType === "raffle" &&
                                userRegGroupData?.assignDiscordRoleOnSelectionFlag && (
                                  <div className="col-lg-12 sp-div-margin mb-3 mb-lg-5 mt-lg-3 blur-bg py-md-4 py-3">
                                    <p className="text-center re-text m-0 d-block">
                                      You have been assigned <b> {isWinnerRole[0]?.roleName}</b> role in
                                      <b> {isWinnerGuildData[0]?.name}</b> discord.
                                    </p>
                                  </div>
                                )}
                              {isWinner &&
                                userRegGroupData?.groupType === "qna" &&
                                userRegGroupData?.assignDiscordRoleOnSelectionFlag && (
                                  <div className="col-lg-12 sp-div-margin mb-3 mb-lg-5 mt-lg-3 blur-bg py-md-4 py-3">
                                    <p className="text-center re-text m-0 d-block">
                                      You have been assigned <b> {isWinnerRole[0]?.roleName}</b> role in
                                      <b> {isWinnerGuildData[0]?.name}</b> discord.
                                    </p>
                                  </div>
                                )}
                              {userRegGroupData?.groupType === "first-come" &&
                                userRegGroupData?.assignDiscordRoleOnSelectionFlag && (
                                  <div className="col-lg-12 sp-div-margin mb-3 mb-lg-5 mt-lg-3 blur-bg py-md-4 py-3">
                                    <p className="text-center re-text m-0 d-block">
                                      You have been assigned <b> {isWinnerRole[0]?.roleName}</b> role in
                                      <b> {isWinnerGuildData[0]?.name}</b> discord.
                                    </p>
                                  </div>
                                )}
                            </div>
                          </div>
                        )
                      ) : (
                        activeGroups.map((groupItem, index) => (
                          <GroupItem
                            slug={slug}
                            index={index}
                            status={status}
                            groupItem={groupItem}
                            serverData={serverData}
                            projectData={projectData}
                            groupWiseRegs={groupWiseRegs}
                            setShowResults={setShowResults}
                            metamaskHandler={metamaskHandler}
                            fillQuestionnaire={fillQuestionnaire}
                            setFillQuestionnaire={setFillQuestionnaire}
                            fetchUserDetail={() => setRefetchUserDetails(!refetchUserDetails)}
                            isRegTimer={isRegTimer}
                            showSnackBar={showSnackBar}
                          />
                        ))
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : (
              <div className="container">
                <div className="opps-box">
                  <div className="opps-text">
                    <h1>Oops!</h1>
                    <p>This Collab has been paused. Please try again after some time.</p>
                  </div>
                  <div className="opps-icon">
                    <img src={Oops} alt="Oops" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer metamaskHandler={metamaskHandler} isProjectPaused={!projectData?.isActive} />
        </>
      )}
      {isSlugNotFound && (
        <>
          <div className="container">
            <div className="opps-box">
              <div className="opps-text">
                <h1>{!slug ? "You are being redirected" : "Slug not found!"}</h1>
              </div>
              <div className="opps-icon">
                <img src={Oops} alt="Oops" />
              </div>
            </div>
          </div>
          <Footer isSlugNotFound={isSlugNotFound} />
        </>
      )}
    </div>
  );
}

export default Register;
