import { useSelector } from "react-redux";
import { Slide, ToastContainer } from "react-toastify";
import { Routes, Route } from "react-router-dom";
import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { WagmiConfig, configureChains, createClient } from "wagmi";
import { goerli, mainnet, polygon, fantom } from "wagmi/chains";

import { getProjectId } from "./utils/EnvUtil";
import Register from "./pages/Register";
import Loader from "./assets/8080laoder.gif";
import closeIcon from "./assets/toastClose.png";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  const loader = useSelector(({ loader }) => loader);

  // Configure wagmi and web3modal
  const projectId = getProjectId();
  const chains = [goerli, mainnet, polygon, fantom];
  const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
  const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({ version: 1, projectId, chains }),
    provider,
  });
  const ethereumClient = new EthereumClient(wagmiClient, chains);

  return (
    <div>
      <WagmiConfig client={wagmiClient}>
        <Routes>
          <Route path="/" element={<Register />} />
          <Route path="/:slug" element={<Register />} />
        </Routes>
        <ToastContainer
          position={"top-center"}
          autoClose={3000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          closeButton={<img className="toast-close-icon" src={closeIcon} alt="close" />}
          transition={Slide}
        />
        <div
          style={
            !loader
              ? {
                  animation: "outAnimation 1000ms ease-out",
                  animationFillMode: "forwards",
                }
              : {}
          }
        >
          <div className="custom-loader">
            <img src={Loader} alt="loading..." />
          </div>
        </div>
      </WagmiConfig>
      <Web3Modal
        ethereumClient={ethereumClient}
        projectId={projectId}
        themeVariables={{
          "--w3m-font-family": "KarlaMedium",
          "--w3m-accent-color": "#ffffff",
          "--w3m-background-border-radius": "12px",
          "--w3m-accent-fill-color": "#A13B4F",
          "--w3m-background-color": "#ffffff",
          "--w3m-container-border-radius": "12px",
          "--w3m-text-big-bold-font-family": "KarlaMedium",
          "--w3m-z-index": "9999",
          "--w3m-text-medium-regular-font-family": "KarlaMedium",
        }}
        themeMode={"dark"}
      />
    </div>
  );
}

export default App;
