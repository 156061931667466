import axios from "axios";
import { cloneDeep } from "lodash";
import { shortenText } from "./common";

export const API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL || "https://8080auth.0xytocin.online";
export const API_FILES_URL = process.env.REACT_APP_API_FILES_URL || "https://8080files.0xytocin.online";
export const API_ALLOWLIST_URL = process.env.REACT_APP_API_ALLOWLIST_URL || "https://8080allowlist.0xytocin.online";

export const instance = axios.create({
  timeout: 20000,
  baseURL: API_ALLOWLIST_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const setAuthHeader = (token) => {
  instance.defaults.headers["Authorization"] = token ? "Bearer " + token : null;
};

export const ApiCall = async (method = "GET", path, data = {}) => {
  let resp = null;

  const res = await instance({
    method,
    url: path,
    data,
    timeout: 20000,
  });

  if (res?.data && res.data.data && res.data.status === "OKAY") {
    resp = res.data.data;
  } else {
    throw new Error(res?.data?.message || "Api Error");
  }

  return resp;
};

export const ApiAuth = async (data) => {
  let resp = null;
  const res = await axios.post(API_AUTH_URL + "/rest/auth/login", data);
  if (res.data && res.status === 200) {
    resp = res.data;
  } else {
    throw new Error(res?.data?.message || "Auth api Error");
  }
  return resp;
};

export const cutAddress = (account, character) => {
  return account.substring(0, character) + "...." + account.substring(account.length - 4);
};

export const cutTwitterAddress = (accountsArray, expectedLength, groupCriteriaData, isRegClicked) => {
  const twitterInfo = groupCriteriaData?.followTwitterAccountsInfo;

  let finalArr = [];

  const recursion = (accounts) => {
    if (accounts.length > expectedLength) {
      accounts.pop();
      recursion(accounts);
    }
    return accounts;
  };

  if (accountsArray.length >= expectedLength) {
    finalArr = recursion(cloneDeep(accountsArray));
  } else {
    finalArr = accountsArray;
  }

  return finalArr.map((item, index) => {
    const isValid = groupCriteriaData?.isFollowTwitterAccounts ? true : twitterInfo && twitterInfo[item];
    return (
      <>
        <a
          style={{ color: !isRegClicked || isValid ? "white" : "#c21d1d" }}
          className="text-decoration-none"
          href={`https://twitter.com/intent/follow?screen_name=${item}`}
          target={"_blank"}
        >
          {shortenText(item, 20)}
        </a>
        {index === finalArr.length - 1 ? (accountsArray.length > expectedLength ? " " : " ") : ", "}
      </>
    );
  });
};

export const twitterToString = (arr, groupCriteriaData, isRegClicked) => {
  const twitterInfo = groupCriteriaData?.followTwitterAccountsInfo;
  return arr.map((item, index) => {
    const isValid = groupCriteriaData?.isFollowTwitterAccounts ? true : twitterInfo && twitterInfo[item];
    return (
      <span style={{ color: !isRegClicked || isValid ? "white" : "#c21d1d" }}>
        <a className="text-decoration-none" href={`https://twitter.com/${item}`} target={"_blank"}>
          {`@${shortenText(item, 20)}${index !== arr.length - 1 ? ", " : ""}`}
        </a>
      </span>
    );
  });
};

export const discordToString = (arr) => {
  let l = [];
  for (let i = 0; i < arr.length; i++) l.push(arr[i][0]);
  return l.join(", ");
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const copyTextToClipboard = async (textToCopy) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(textToCopy);
  } else {
    return document.execCommand("copy", true, textToCopy);
  }
};
