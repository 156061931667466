import React from "react";
import { BsFillCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { Navbar } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";

import { cutAddress } from "./utils/ApiUtils";

function Header({ projectData, metamaskHandler }) {
  const { isConnected, address, status } = useAccount();

  const loggedUserData = useSelector(({ loggedUserData }) => loggedUserData);

  return (
    <>
      {process.env.REACT_APP_BANNER_TEXT && (
        <Navbar fixed="top" className="fixed-navbar">
          <p>
            {process.env.REACT_APP_BANNER_TEXT}
            {process.env.REACT_APP_BANNER_INFO_TOOLTIP_TEXT && (
              <div className="custom-tooltip" style={{ cursor: "pointer" }}>
                {" "}
                <AiOutlineInfoCircle />
                <span className="tooltip-text custom-tooltip-bottom text-start p-2">
                  {process.env.REACT_APP_BANNER_INFO_TOOLTIP_TEXT}
                </span>
              </div>
            )}
          </p>
        </Navbar>
      )}
      <div
        className={`${
          process.env.REACT_APP_BANNER_TEXT && "top-sec-bar"
        } navbar-fixed-top comon-page float-start w-100`}
      >
        <div className="container">
          <div className="top-inside-div py-3 py-sm-4 mb-0 mt-3 mt-sm-4 top-sec-div-one">
            <div className="row row-cols-2 row-cols-lg-2 align-items-center">
              <div className="col" style={{ width: "40%" }}>
                <div className="pro-image-div">
                  <img src={projectData?.projectLogo} alt="logo" />
                </div>
              </div>
              <div className="col d-flex justify-content-end" style={{ width: "60%" }}>
                {isConnected && loggedUserData ? (
                  <button type="button" className="btn active-id-btn connected" style={{ maxWidth: "100%" }}>
                    {/* <span className="icon-n3">
                      <BsFillCircleFill />
                    </span> */}
                    <span>{cutAddress(address, 5)}</span>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn comon-btn-ree funt-btn"
                    onClick={metamaskHandler}
                    style={{ maxWidth: "100%" }}
                  >
                    {status === "connected" ? "Authenticate" : "Connect Wallet"}
                    <span className="btn-arowwn">
                      <img src="images/arrow.png" alt="bn" className="d-lg-block" />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
