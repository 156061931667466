export const shortenText = (str, toLen = str.length) => {
  const newString = str.substring(0, toLen) + "...";
  return str.length > toLen + 3 ? newString : str;
};

export const removeHttp = (url) => url.replace(/^https?:\/\//, "");

export const convertToSubString = (str) => {
  const newString = str?.substring(0, 28) + "..." + str?.substring(str.length - 10, str.length);
  return str.length > 33 ? newString : str;
};
