import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "../../store/reducer";
import { delay } from "../../utils/ApiUtils";

function QuestionsRender({
  isRegTimer,
  questions = [],
  groupId,
  register,
  verifiedAccessCode,
  groupItem,
  isReadyToReg,
  showSnackBar,
  criteriaLoader,
  isCriteriaQualified,
}) {
  const mints = Math.floor(isRegTimer / 60);
  const secs = Math.floor(isRegTimer % 60);

  const dispatch = useDispatch();

  const [qaInputData, setQaInputData] = useState([]);
  const [qaErrors, setQaErrors] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onChangeInput = (name, value, i, isMcq) => {
    if (!qaInputData[i]) qaInputData[i] = { [name]: isMcq ? [] : "" };
    // if (isMcq) {
    //   const isExitInx = qaInputData[i][name].findIndex((item) => item === value);
    //   isExitInx === -1 ? qaInputData[i][name].push(value) : (qaInputData[i][name][isExitInx] = null);
    //   qaInputData[i][name] = [...new Set(qaInputData[i][name])].filter((item) => item);
    // } else
    qaInputData[i][name] = isMcq ? [value] : value;

    setQaInputData([...qaInputData]);
    if (isSubmitted) validateQA();
  };

  const validateQA = () => {
    let errorArr = [];
    questions.map((item, i) => {
      if (item.responseType === "must" || item.responseType === "correctly") {
        const isVal =
          item?.questionType === "numeric" && qaInputData[i]?.answer === 0 ? false : !qaInputData[i]?.answer;
        if (isVal) errorArr[i] = "Response required.";

        if (item?.questionType === "mcq" && qaInputData[i]?.answer.length < 0)
          errorArr[i] = "Please select atleast one option.";
        if (item?.questionType === "textual") {
          if (item?.textConditionType === "long-type" && qaInputData[i]?.answer.length > 2000)
            errorArr[i] = "Maximum 2000 characters allowed.";
          if (item?.textConditionType === "short-type" && qaInputData[i]?.answer.length > 300)
            errorArr[i] = "Maximum 300 characters allowed.";
        }
      }

      if (item.responseType === "optional") {
        if (item?.questionType === "textual") {
          if (item?.textConditionType === "long-type" && qaInputData[i]?.answer.length > 2000)
            errorArr[i] = "Maximum 2000 characters allowed.";
          if (item?.textConditionType === "short-type" && qaInputData[i]?.answer.length > 300)
            errorArr[i] = "Maximum 300 characters allowed.";
        }
      }
      // if (item?.questionType === "numeric" && item?.responseType === "correctly") {
      //   const { comparisonType, value } = item?.numericType;
      //   const anw = Number(qaInputData[i]?.answer);
      //   if (comparisonType === "GT" && !(anw > Number(value))) errorArr[i] = `Must be greater than ${value}`;
      //   if (comparisonType === "LT" && !(anw < Number(value))) errorArr[i] = `Must be less than ${value}`;
      //   if (comparisonType === "EQ" && !(anw == Number(value))) errorArr[i] = `Must be Equal to ${value}`;
      // }

      // if (item?.questionType === "mcq" && item?.responseType === "correctly") {
      //   const { isCorrect } = item?.mcqOptions.find((it) => it.option === qaInputData[i]?.answer) || {
      //     isCorrect: false,
      //   };
      //   if (!isCorrect) errorArr[i] = "Selected Option is wrong";
      // }
    });
    setQaErrors(errorArr);
    return errorArr;
  };

  const validateRegister = async () => {
    try {
      setIsSubmitted(true);
      const errorArr = validateQA();
      if (errorArr.length === 0) {
        let qnaData = {};
        questions.map((item, i) => {
          if (qaInputData[i]?.answer) {
            qnaData[item._id] = qaInputData[i]?.answer;
          }
        });
        await register(groupId, qnaData);
      }
      setIsSubmitted(false);
    } catch (error) {
      setIsSubmitted(false);
      console.log(error);
    }
  };

  const isOptional = questions?.filter((item) => item.responseType !== "optional");

  return (
    <div>
      <div className="blur-bg py-3 mb-3">
        <h2 className="text-white">Questionnaire</h2>
        {questions?.map((qaItem, i) => (
          <div className="questionnaire-box" key={i}>
            <div className="questionnaire-box-content">
              <div className="question">
                <div className="question-head">
                  <div className="question-head-left">
                    <div className="number-list">
                      <span>{i + 1}</span>
                    </div>
                    <p>
                      {qaItem?.question}
                      {qaItem?.responseType === "must" || qaItem?.responseType === "correctly" ? " * " : ""}
                    </p>
                  </div>
                  <div className="question-head-right">
                    <p>
                      {qaItem?.questionType === "mcq"
                        ? "MCQ"
                        : qaItem?.questionType === "numeric"
                        ? "Numeric"
                        : qaItem?.questionType === "textual"
                        ? "Text-Based"
                        : ""}
                    </p>
                  </div>
                </div>
                {qaItem?.questionType === "mcq" && (
                  <div className="mcq-option">
                    <ul>
                      {qaItem?.mcqOptions?.map((option, ind) => (
                        <li
                          className={
                            qaInputData[i] && qaInputData[i]["answer"].includes(option?.option) ? "active" : ""
                          }
                          key={ind}
                          onClick={() => onChangeInput("answer", option?.option, i, true)}
                        >
                          <div className="option-number">
                            <span>{[String.fromCharCode(ind + 97)]}</span>
                          </div>
                          <p>{option?.option}</p>
                          {/* option?.isCorrect */}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {qaItem?.questionType === "textual" && (
                  <div className="text-based-textarea">
                    <textarea
                      placeholder={`Keep your response within ${
                        qaItem?.textConditionType === "long-type"
                          ? "2000"
                          : qaItem?.textConditionType === "short-type"
                          ? "300"
                          : ""
                      } characters`}
                      rows="4"
                      onChange={(e) => onChangeInput("answer", e.target.value, i)}
                      value={qaInputData[i] && qaInputData[i]["answer"]}
                    />
                  </div>
                )}
                {qaItem?.questionType === "numeric" && (
                  <div className="text-numeric">
                    <input
                      inputMode="numeric"
                      onWheelCapture={(e) => {
                        e.target.blur();
                      }}
                      onChange={(e) => {
                        onChangeInput(
                          "answer",
                          e.target.value
                            ? !isNaN(e.target.value)
                              ? Number(e.target.value)
                              : (qaInputData[i] && qaInputData[i]["answer"]) || ""
                            : "",
                          i
                        );
                      }}
                      value={qaInputData[i] && qaInputData[i]["answer"]}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                )}
                {qaErrors[i] && (
                  <div className="questionnaire form-error">
                    <div className="error-text">
                      <span>{qaErrors[i]}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={`justify-content-between mt-md-4 mt-sm-3 mt-2 question-btn-wrap`}>
        <p className="qna-register-text">{isOptional.length > 0 && "* Mandatory to answer in order to register"}</p>
        {!isCriteriaQualified && isRegTimer ? (
          <div className="custom-tooltip">
            <div
              style={{ display: "inline-block" }}
              onClick={() => showSnackBar("Too many requests received. Please try again when timer stops.")}
            >
              <button className={`btn register-btn2`} disabled={true}>
                {`${mints <= 9 ? "0" + mints : mints}:${secs <= 9 ? "0" + secs : secs}`}
              </button>
            </div>
            <span className="tooltip-text custom-tooltip-bottom">
              Too many requests received. Please try again when timer stops.
            </span>
          </div>
        ) : (
          <button
            className={`btn register-btn2 comon-btn-ree`}
            onClick={async () => {
              validateRegister();
              await delay(1000);
            }}
            disabled={
              (!isCriteriaQualified && ((groupItem?.referralCodesRequired && !isReadyToReg) || criteriaLoader)) ||
              isSubmitted
            }
          >
            {isSubmitted ? "Registering..." : "Register"}
          </button>
        )}
      </div>
    </div>
  );
}

export default QuestionsRender;
