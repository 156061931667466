import React from "react";
import { BsFillCaretRightFill } from "react-icons/bs";

const RegButton = ({
  isRegTimer,
  isSocialConnected,
  onClick,
  isReadyToReg,
  isRegClicked,
  verifiedAccessCode,
  className = "",
  isReadyQNA = false,
  groupItem,
  showSnackBar,
  isStartedTime,
  criteriaLoader,
  isCriteriaQualified,
}) => {
  const mints = Math.floor(isRegTimer / 60);
  const secs = Math.floor(isRegTimer % 60);
  return (
    <>
      <div className="custom-tooltip">
        <div
          style={{ display: "inline-block" }}
          className="question-btn-div"
          onClick={() =>
            isReadyToReg
              ? !verifiedAccessCode && groupItem?.referralCodesRequired
                ? showSnackBar("Access Code is mandatory.")
                : isRegTimer && showSnackBar("Too many requests received. Please try again when timer stops.")
              : isSocialConnected
              ? !isStartedTime
                ? "Registration has not started yet."
                : showSnackBar("Registration is closed.")
              : showSnackBar("Please connect all social accounts.")
          }
        >
          {!isCriteriaQualified && isRegTimer ? (
            <button className={`btn register-btn2 ${className}`} disabled={true}>
              {`${mints <= 9 ? "0" + mints : mints}:${secs <= 9 ? "0" + secs : secs}`}
            </button>
          ) : (
            <button
              className={isReadyQNA ? className : `${`btn register-btn2 ${className}`}`}
              onClick={onClick}
              disabled={
                !isCriteriaQualified &&
                ((groupItem?.referralCodesRequired && !verifiedAccessCode) || !isReadyToReg || criteriaLoader)
              }
            >
              {groupItem?.groupType === "qna" && isReadyQNA ? (
                <>
                  Fill Questionnaire <BsFillCaretRightFill />
                </>
              ) : (
                <>
                  {isRegClicked
                    ? "Re-check Eligibility"
                    : groupItem?.groupType === "qna"
                    ? "Check Eligibility"
                    : "Register"}
                  {!isRegClicked && (
                    <span className="btn-arowwn">
                      <img src="images/arrow.png" alt="bn" className="d-lg-block" />
                    </span>
                  )}
                </>
              )}
            </button>
          )}
        </div>
        {isReadyToReg ? (
          !verifiedAccessCode && groupItem?.referralCodesRequired ? (
            <span className="tooltip-text custom-tooltip-bottom">Access Code is mandatory</span>
          ) : isRegTimer ? (
            <span className="tooltip-text custom-tooltip-bottom">
              Too many requests received. Please try again when timer stops.
            </span>
          ) : (
            <></>
          )
        ) : (
          <span className="tooltip-text custom-tooltip-bottom">
            {isSocialConnected
              ? !isStartedTime
                ? "Registration has not started yet."
                : "Registration is closed"
              : "Please connect all social accounts."}
          </span>
        )}
      </div>
    </>
  );
};

export default RegButton;
