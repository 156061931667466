export const getProjectId = () => {
  return "1caac3b2728e2b882e7c140e1d9ab392";
};

export const getTheme = () => {
  if (typeof window === "undefined") {
    return "dark";
  }

  return "dark";
};
