import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiCall } from "../../utils/ApiUtils";
import InputCode from "./InputCode";

const AccessCodeModal = ({
  show,
  groupId,
  groupItem,
  slug,
  handleClose,
  accessCode,
  setAccessCode,
  setVerifiedAccessCode,
  showError,
  setShowError,
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      const code = accessCode;
      if (code.length < 6) return setShowError("Invalid access code.");
      setLoading(true);
      await ApiCall("GET", `/rest/criteria-check/${slug}/${groupId}/accessCode/${code}`);
      toast.success(`Access code verified!`);
      setVerifiedAccessCode(code);
      setAccessCode("");
      setShowError(false);
      handleClose();
      setLoading(false);
    } catch (error) {
      setShowError(error?.response?.data?.message || "Invalid access code.");
      setLoading(false);
    }
  };

  return (
    <Modal className="blur-input-modal" centered show={show} onHide={handleClose}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title>Access Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {groupItem?.referralCodesRequired
            ? "Access code is mandatory"
            : "Enter access code to skip qualification criteria"}
        </p>
        <div className="code-input-field">
          <InputCode
            length={accessCode.length}
            loading={loading}
            accessCode={accessCode}
            setAccessCode={setAccessCode}
            showError={showError}
            setShowError={setShowError}
            inputMode={"text"}
          />
        </div>
        {showError && (
          <div className="form-error">
            <div className="error-text">
              <span className="info">i</span>
              <span>{showError || `Invalid access code.`}</span>
            </div>
          </div>
        )}
        <div className="confirm-btn text-end mb-4 mt-3">
          <button disabled={loading} onClick={onSubmit}>
            Confirm
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AccessCodeModal;
