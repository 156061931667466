import axios from "axios";
import { ApiCall } from "./ApiUtils";

//get project details based on slug
export const getProjectDetails = async (slug) => {
  const resp = await ApiCall("GET", `/rest/registration/${slug}`);
  return resp;
};

//get user details based on slug
export const getUserDetails = async (slug) => {
  const resp = await ApiCall("GET", `/rest/registration/${slug}/userDetails`);
  return resp;
};

// add to allowlist with slug and groupId
export const addToAllowlist = async (slug, groupId, accessCode, qaInputData) => {
  const resp = await ApiCall("POST", `/rest/registration/${slug}/addToAllowlist/${groupId}`, {
    ...(accessCode ? { accessCode: accessCode } : ""),
    ...(qaInputData ? { response: qaInputData } : ""),
  });
  return resp;
};

export const fetchFile = async (UrlById) => {
  if (!UrlById) return;
  const { data } = await axios.get(`https://8080files.0xytocin.online/rest/files/getUrlById/${UrlById}`);
  return data;
};

// send verification code through email
export const requestOTP = async (slug, email) => {
  const resp = await ApiCall("POST", `/rest/registration/${slug}/email/request`, { email });
  return resp;
};

export const verifyOTP = async (slug, email, otp) => {
  const resp = await ApiCall("POST", `/rest/registration/${slug}/email/verify`, { email, otp });
  return resp;
};
