import React from "react";
import { Modal } from "react-bootstrap";

const TwitterConnectModal = ({ show, onConnect, handleClose }) => {
  return (
    <Modal className="blur-input-modal" centered show={show} onHide={handleClose}>
      <Modal.Body>
        <p className="mt-4">
          If you are redirected to the Twitter app, then please return here after connecting your account and refresh
          the page.
        </p>

        <div className="confirm-btn text-end mb-4 mt-3">
          <button
            onClick={(e) => {
              onConnect(e);
              handleClose();
            }}
          >
            Connect
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TwitterConnectModal;
