import React from "react";
import { GiInfinity } from "react-icons/gi";

const GroupInfo = ({ groupItem, groupWiseRegs }) => {
  const noOfRegCount = groupWiseRegs.find((item1) => item1._id === groupItem.id)?.count;

  return (
    <>
      <div className="mobile-view">
        <div className="blur-bg group-block">
          <div className="group-block-div">
            {/* <div className="mt-0 d-flex mb-2">
              <div className="bs-dot-span" />
              <h5>
                <b>{noOfRegCount ? noOfRegCount : 0}</b> Registrations so far
              </h5>
            </div> */}
            <div className="mt-0 d-flex mb-2">
              <div className="bs-dot-span" />
              {groupItem.maxRegistrations === null ? (
                <h5>
                  <GiInfinity /> Max Allowed Registrations
                </h5>
              ) : (
                <h5 style={groupItem.maxRegistrations?.toString().length > 6 ? { fontSize: "30px" } : null}>
                  <b>{groupItem.maxRegistrations}</b> Max Allowed Registrations
                </h5>
              )}
            </div>
            <div className="mt-0 d-flex mb-2">
              <div className="bs-dot-span" />
              {!groupItem.isPrivate && <h5>All registrants will be on the final list.</h5>}
              {groupItem.isPrivate && groupItem.groupType === "raffle" && (
                <h5>
                  <b>{groupItem.noOfWinners}</b>
                  &nbsp;{groupItem.noOfWinners === 1 ? "winner" : "winners"} to be picked
                </h5>
              )}
              {groupItem.isPrivate && groupItem.groupType === "qna" && (
                <h5>Only selected registrants will be on the final list</h5>
              )}
              {groupItem.isPrivate && groupItem.groupType === "first-come" && (
                <h5>{`All registrants will be on the final list`}</h5>
              )}
              {groupItem.firstpara && (
                <div className="first-cum-st1">
                  <h1>
                    {groupItem.fist1}
                    <br />
                    {groupItem.last1}
                  </h1>
                  <p> {groupItem.firstpara} </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="desktop-view">
        <div className="blur-bg group-block">
          <div className="group-block-div">
            <div className="row main-row row-cols-2 row-cols-lg-2 g-lg-4 m-0">
              {/* <div className="col text-center mt-0">
                <h1>{noOfRegCount ? noOfRegCount : 0}</h1>
              </div> */}
              <div className="col text-center mt-0">
                {groupItem.maxRegistrations === null ? (
                  <GiInfinity className="infinity-text" />
                ) : (
                  <h1 style={groupItem.maxRegistrations?.toString().length > 6 ? { fontSize: "30px" } : null}>
                    {groupItem.maxRegistrations}
                  </h1>
                )}
              </div>
              <div className="col text-center mt-0 fcfs-upper-div">
                <div>
                  {groupItem.isPrivate && groupItem.groupType === "first-come" && (
                    <div className="d-flex flex-column">
                      <h1 className="fcfs-text">FIRST COME</h1>
                      <h1 className="fcfs-text fcfs-line-height">FIRST SERVE</h1>
                    </div>
                  )}
                  <h1>
                    {groupItem.isPrivate
                      ? groupItem.groupType === "raffle"
                        ? "RAFFLE"
                        : groupItem.groupType === "qna" && "QnA"
                      : "PUBLIC"}
                  </h1>
                </div>
              </div>
            </div>
            <div className="row row-cols-2 row-cols-lg-2 g-lg-4 m-0">
              {/* <div className="col text-center mt-0 group-info-text">
                <h5 className="fw-bolder"> Registrations</h5>
              </div> */}
              <div className="col text-center mt-0 group-info-text">
                <h5 className="fw-bolder">Max Allowed Registrations</h5>
              </div>
              <div className="col text-center mt-0 group-info-text">
                {!groupItem.isPrivate && <h5 className="mb-2 fw-bolder">All registrants will be on the final list.</h5>}
                {groupItem.isPrivate && groupItem.groupType === "raffle" && (
                  <h5 className="mb-2 fw-bolder">
                    {groupItem.noOfWinners}
                    &nbsp;{groupItem.noOfWinners === 1 ? "winner" : "winners"} to be picked
                  </h5>
                )}
                {groupItem.isPrivate && groupItem.groupType === "qna" && (
                  <h5 className="mb-2 fw-bolder">Only selected registrants will be on the final list</h5>
                )}
                {groupItem.isPrivate && groupItem.groupType === "first-come" && (
                  <h5 className="fw-bolder">{`All registrants will be on the final list`}</h5>
                )}
                {groupItem.firstpara && (
                  <div className="first-cum-st1">
                    <h1>
                      {groupItem.fist1}
                      <br />
                      {groupItem.last1}
                    </h1>
                    <p> {groupItem.firstpara} </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupInfo;
