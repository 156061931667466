import React, { useRef } from "react";

const InputCode = ({ length, showError, setShowError, loading, setAccessCode, accessCode, inputMode }) => {
  const inputs = useRef([]);

  // const processInput = (e, slot) => {
  //   setShowError(false);
  //   const num = e.target.value;
  //   if (/[^A-Za-z0-9]/.test(num)) return;
  //   const newCode = [...accessCode];
  //   newCode[slot] = num;
  //   setAccessCode(newCode);
  //   if (slot !== length - 1) {
  //     inputs.current[slot + 1].focus();
  //   }
  // };

  // const onKeyUp = (e, slot) => {
  //   if (e.keyCode === 8 && !accessCode[slot] && slot !== 0) {
  //     const newCode = [...accessCode];
  //     newCode[slot - 1] = "";
  //     setAccessCode(newCode);
  //     inputs.current[slot - 1].focus();
  //   }
  // };

  return (
    <div className="code-input">
      <div className={`${showError ? "error" : ""} w-100`}>
        <input
          maxLength={6}
          autoFocus={accessCode === ""}
          readOnly={loading}
          onChange={(e) => setAccessCode(e.target.value)}
          inputMode={inputMode}
          value={accessCode}
        />
      </div>
      {/* <div className={`code-inputs ${showError ? "error" : ""}`}>
        {accessCode.map((num, idx) => {
          return (
            <input
              key={idx}
              type="text"
              inputMode="text"
              maxLength={1}
              value={num}
              autoFocus={!accessCode[0].length && idx === 0}
              onFocusCapture={() => {
                if(inputs.current[Math.max(idx - 1, 0)].value === "") inputs.current[Math.max(idx - 1, 0)].focus();
              }}
              readOnly={loading}
              onChange={(e) => processInput(e, idx)}
              onKeyUp={(e) => onKeyUp(e, idx)}
              ref={(ref) => (inputs.current[idx] = ref)}
              onPaste={(e) => {
                if (e.target.type === "text") {
                  let data = e.clipboardData.getData("Text");
                  data = data.split("");
                  accessCode.map((_, i) => {
                    if (data[i]) {
                      accessCode[i] = data[i];
                    }
                  });
                  setAccessCode([...accessCode]);
                }
              }}
            />
          );
        })}
      </div> */}
    </div>
  );
};

export default InputCode;
