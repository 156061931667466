import React from "react";
import { Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";

function Footer({ metamaskHandler, isSlugNotFound, isProjectPaused }) {
  const { status } = useAccount();
  const loggedUserData = useSelector(({ loggedUserData }) => loggedUserData);

  return (
    <footer
      className="float-start w-100 ft-new"
      style={{ paddingBottom: loggedUserData ? "60px" : "100px", paddingTop: "10px" }}
    >
      <div className="container">
        <div className="col-lg-8 text-center d-block mx-auto">
          <h3 className="text-white mb-0 footer-text"> Powered by </h3>
          <div className="footer-lg">
            <img className="footer-logo" src="images/8080-logo.png" alt="footer logo" />
          </div>
          <p className="text-white mb-0 recive-text1 footer-text"> All Rights Reserved</p>
        </div>
      </div>
      {!loggedUserData && !isSlugNotFound && !isProjectPaused && (
        <Navbar fixed="bottom" className="footer fixed-navbar d-lg-none">
          <button
            type="button"
            className="btn comon-btn-ree funt-btn"
            onClick={metamaskHandler}
            style={{ maxWidth: "100%" }}
          >
            {status === "connected" ? "Authenticate" : "Connect Wallet"}
          </button>
        </Navbar>
      )}
    </footer>
  );
}
export default Footer;
