import React from "react";
import { IoMdCloseCircle, IoMdCheckmarkCircle } from "react-icons/io";
import Loader from "../../assets/circlularloader.gif";
import { cutAddress, cutTwitterAddress, twitterToString } from "../../utils/ApiUtils";
import { convertToSubString } from "../../utils/common";
import { ReactComponent as RedirectIcon } from "../../assets/redirect-icon.svg";

const GroupCriteria = ({ status, groupItem, serverData, groupCriteriaData, criteriaLoader }) => {
  const {
    minBalance,
    isPrivate,
    twitterActivity,
    discordServerRoles = [],
    walletType,
    isWalletBalance,
    tokengating,
  } = groupItem;
  const isRegClicked = Object.keys(groupCriteriaData).length > 0;

  const ShowCheckBox = ({ isValid }) => (
    <>
      {criteriaLoader && !groupCriteriaData.qualifiedForQNa ? (
        <div className="circular-criteria-loader">
          <img src={Loader} alt="loading..." />
        </div>
      ) : (
        status === "connected" &&
        isRegClicked && (
          <div className="circular-criteria">
            {isValid ? <IoMdCheckmarkCircle color="#00ff80" /> : <IoMdCloseCircle color="#c21d1d" />}
          </div>
        )
      )}
    </>
  );

  return (
    <div className="blur-bg group-qualification crytaria-div">
      <div className="row row-cols-1 row-cols-sm-1 row-cols-lg-2 align-items-center m-0">
        <div className="col">
          <h2 className="m-0 text-white mb-sm-0 mx-lg-3">Qualification Criteria</h2>
        </div>
        <div className="col mt-lg-4 mt-sm-0 mb-lg-4 mb-0">
          <ul className="list-unstyled m-0">
            {isPrivate && (
              <li>
                <div>
                  {discordServerRoles.map((item) => {
                    const foundRole = serverData?.find((it) => it.id === item.guildId);
                    const roleInfo =
                      groupCriteriaData?.discordRolesInfo && groupCriteriaData?.discordRolesInfo?.[item.guildId];
                    const roleValid = roleInfo && Object.values(roleInfo);
                    const isValid = groupCriteriaData?.hasDiscordRoles
                      ? true
                      : roleValid && roleValid.filter((item) => item).length === roleValid.length;

                    return (
                      <div className="d-flex mb-2">
                        <ShowCheckBox isValid={isValid} />
                        {!criteriaLoader && Object.keys(groupCriteriaData).length === 0 && (
                          <div className="bs-dot-span" />
                        )}
                        <span className="d-block">
                          Must have{" "}
                          <b>
                            {item?.roles.map((it, i) => (
                              <b
                                style={{
                                  color:
                                    !isRegClicked || groupCriteriaData?.hasDiscordRoles
                                      ? true
                                      : roleInfo?.[it.roleId]
                                      ? "white"
                                      : "#c21d1d",
                                }}
                              >
                                {`${item?.roles?.length > 1 && i === item?.roles?.length - 1 ? " & " : ""}${
                                  it.roleName
                                }${i < item?.roles?.length - 2 ? ", " : " "}`}
                              </b>
                            ))}
                          </b>
                          role in <b> {foundRole?.name} </b> server on discord
                        </span>
                      </div>
                    );
                  })}
                </div>
              </li>
            )}
            {isPrivate && twitterActivity.length > 0 && (
              <li className="d-flex mb-2">
                <ShowCheckBox isValid={groupCriteriaData?.isFollowTwitterAccounts} />
                {!criteriaLoader && Object.keys(groupCriteriaData).length === 0 && <div className="bs-dot-span" />}
                <span>
                  Must follow <b>{cutTwitterAddress(twitterActivity, 5, groupCriteriaData, isRegClicked)}</b>
                  {twitterActivity.length > 5 && (
                    <>
                      and{" "}
                      <span className="tooltip-tweet">
                        <button className="tooltip-tweet-btn text-white">
                          {" "}
                          <b>{twitterActivity.length - 5} more</b>
                        </button>
                        <span className="tooltip-tweet-box">
                          <div>
                            {twitterToString(
                              twitterActivity.slice(5, twitterActivity.length),
                              groupCriteriaData,
                              isRegClicked
                            )}
                          </div>
                        </span>
                      </span>
                    </>
                  )}{" "}
                  on twitter
                </span>
              </li>
            )}

            {isWalletBalance && Boolean(minBalance) && minBalance > 0 && (
              <li className="d-flex mb-2">
                <ShowCheckBox isValid={groupCriteriaData?.isMinBalanceAvailable} />
                {!criteriaLoader && Object.keys(groupCriteriaData).length === 0 && <div className="bs-dot-span" />}
                <span>
                  Must have
                  <b>{` ${minBalance || ""} ${walletType} `}</b>
                  in wallet
                </span>
              </li>
            )}
            {groupItem?.tweets?.length > 0 && (
              <li className="d-flex mb-2">
                <ShowCheckBox isValid={groupCriteriaData?.isTweetRetweeted && groupCriteriaData?.isTweetLiked} />
                {!criteriaLoader && Object.keys(groupCriteriaData).length === 0 && <div className="bs-dot-span" />}
                <span>
                  Must like and retweet{" "}
                  <span className="tooltip-tweet">
                    <button className="tooltip-tweet-btn text-white">
                      {groupItem?.tweets?.length === 1 ? "this" : "these"}
                    </button>
                    <span className="tooltip-tweet-box">
                      <ul className="list-link">
                        {groupItem?.tweets?.map((item, idx) => (
                          <li key={idx}>
                            <a
                              target="_blank"
                              href={
                                "https://twitter.com/intent/retweet?tweet_id=" + item.tweetUrl.match(/(\d{19,})/)[0]
                              }
                            >
                              <span className="text-ellipsis">{convertToSubString(item.tweetUrl)}</span>{" "}
                              <RedirectIcon width={14} />
                            </a>
                          </li>
                        ))}
                      </ul>
                    </span>
                  </span>{" "}
                  {groupItem?.tweets?.length === 1 ? "tweet" : "tweets"}
                </span>
              </li>
            )}
            {tokengating && groupItem?.token?.address && (
              <li className="d-flex mb-2">
                <ShowCheckBox isValid={groupCriteriaData?.hasTokens} />
                {!criteriaLoader && Object.keys(groupCriteriaData).length === 0 && <div className="bs-dot-span" />}
                <span>
                  Must have <b>{groupItem?.token?.minToken ? groupItem?.token?.minToken : 0}</b> tokens from{" "}
                  <span className="tooltip-tweet">
                    <a
                      target="_blank"
                      href={`https://etherscan.io/address/${groupItem?.token?.address}`}
                      className="tooltip-tweet-btn"
                    >
                      {cutAddress(`${groupItem?.token?.address}`, 4)}
                    </a>
                  </span>
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GroupCriteria;
